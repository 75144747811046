.box-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 94.2vh;
}

.paperForm {
    display: flex;
    height: 300px;
    justify-content: center;
    align-items: center;
    padding: 30px;
    text-align: center;

}