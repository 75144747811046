.box {
  border-radius: 7px;
  /*   border: '2px solid #000', */
  box-shadow: ;
  padding: 16px;
}

.box .close-icon {
  border-radius: 7px;

}

.modal-height2 {
  height: 80vh;
}

.box .icon {
  transition: .6s;
}

.icon:hover {
  transform: rotate(90deg);

}

.box .close-icon:hover {
  background: rgba(0, 0, 0, 0);
}

.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}