*{
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: Imported;
  src: url(../assets/fonts/typography.otf);
}

* {
  font-family: Imported !important;
}


@media print {
  

  html, body {
    height: 100%; /* Use 100% here to support printing more than a single page*/
    
    padding: 0 !important;
    
  }
}


.numeric-theme {
  max-width: 320px;
  margin: 0 auto;
}

.numeric-theme + .simple-keyboard-preview {
  max-width: 320px;
}

.simple-keyboard.hg-theme-default.numeric-theme
  .hg-button.hg-standardBtn.hg-button-at {
  max-width: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  transition: background-color 5000s;
  
}
