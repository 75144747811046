.bussines-name{
  padding: 16px 0px 0px 10px;
}
.rfc-data{
  padding: 0px 0px 0px 10px;
}
.icons-details{
  margin-right: 8px;
}
.details-view{
  margin-top: 16px !important;
  margin-bottom: 30px !important;
  
}
