/* .App {
    font-family: sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  
  .cropper {
    position: relative;
    height: 50vh;
  }
  
  .viewer {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .output {
    position: relative;
    width: 300px;
    overflow: hidden;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.3);
  }
  
  .output img {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left;
  }
   */


   .easy-cropper{
 
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .crop-container {
    position: relative;
    height: 300px;
    width: 500px;
    align-items: center;
  
  }
  .crop-result{
    position: relative;
    height: 300px;
    width: 300px;
    align-items: center;
    
  }
  .size-center{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 80vw;
    margin: 5px 10px;
    justify-content:space-around;
  }
  
  .output {
    position: relative;
    width: 300px;
    overflow: hidden;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.3);
  }
  
  .output img {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left;
  }
  
  
  .title-editor1{
    width: 500px;
  }
  
  .title-editor{
    width: 300px;
  }
  
  
  .center-div {
    display: flex;
    height: calc(90% - 50px);
    justify-content: center;
    align-items: center;
  }
  
  .controlers{
    margin-top: 32px;
    width: 90%;
    display: flex;
  }
  
  .button-container{
  
    width: 90vw;
    display: flex;
    justify-content: flex-end;
    
  
  }
  .button {
    margin: 0 16px;
  }
  
  
  
  
  .images-test{
    height: 300px;
    width: 300px;
  }
    