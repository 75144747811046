@import './purchaseDetails.scss';
 
.fondo-home{
    position: absolute;
    width: 100%;
    height: 200%;
/*     border: 2px solid red; */
}

.container-apps{
    position: relative;
    top: 40vh;
    left: 50%;
    transform: translate(-50%, -55vh);
    width: 70%;
    height: 60%;

    /* border: 10px solid blue; */
   
}

.arrows{
  margin-right: 10px;

}
.container-stepper{
  position: absolute;
  top: 60vh;
  left: 50%;
  transform: translate(-50%, -40vh);
  width: 70%;
  height: 100vh;
  /* border: 10px solid blue; */
  /* border: 2px solid blue; */

}

.border{
  border-style: solid;
  border-color: black;
}

.container-apps .box-apps{
    display: flex;
    padding: 15px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    /* border: 10px solid yellow; */
}

.container-stepper .box-config-business{
  display: flex;
  padding: 15px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  //height: 200px;
  border: 1px solid gray; 
}

.config-steps{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  height: 400px;
}

.final-step{
  display: flex;
  align-items: center;
  justify-content: center;
}


 .titles-apps{
    color: #fff;
    margin-left: 6%;
}

.home-nav{
    position: absolute;
    height: 40vh;
}
.boxArticles {
    position: absolute;

    box-shadow: 0 0  25px #222;
    padding: 6px 16px 16px 16px;
    border-radius: 10px;
  } 
  
.container-apps .box-apps .card-disable{
    max-width: 295px;
    min-width: 295px;
    max-height: 300px;
    margin: 10px;

    /* filter: grayscale(100%) blur(1px); */

    /* background-color: rgb(32, 32, 32); */
}



@media only screen and (max-height: 750px ) {
    .fondo-home{
        position: absolute;
        width: 100%;
        height: 260%;
    }
    .container-apps{
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -55%);
        width: 70%;
        height: 60%;
        /* border: 10px solid blue; */
    }
    .container-stepper{
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -55%);
      width: 80%;
      height: 60%;
      /* border: 10px solid blue; */
    
    }
}


@media only screen and (max-height: 600px ) {
    .container-apps{
        position: static;
        top: none;
        left: none;
        transform: none;
        margin: auto;
        width: 80%;
   
    } 
    .container-stepper{
      position: static;
      top: none;
      left: none;
      transform: none;
      margin: auto;
      width: 99%;
      min-height: 100vh;
    }
    .container-stepper .box-config-business{
      width: 95%;
    }
    .fondo-home{
        position: static;
        height: auto;
    }
    .home-nav{
        position: static;
        height: auto;
    }
}

@media only screen and (max-width: 1400px ) {
    .container-apps{
        width: 90%;
        height: 60%;
     } 


}


@media only screen and (max-width: 1100px ) {
   .container-apps{
        position: static;
        top: none;
        left: none;
        transform: none;
        margin: auto;
        width: 80%;
   
    } 
    .container-stepper{
      position: static;
      top: none;
      left: none;
      transform: none;
      margin: auto;
      width: 90%;
      min-height: 100vh;
    }
    .fondo-home{
        position: static;
        height: auto;
    }
    .home-nav{
        position: static;
        height: auto;
    }
}
.noDataText{
  text-align: center;
  margin-top: 78px;
}

.marginTable{
  margin-bottom: 20px;
}

.margin{
  align-items: center;
  text-align: center;
  margin: 25px;
}

.paginationStyle{
  margin: auto !important;
}

.generic-table{
  padding: 8px 0px 8px 16px;
}
.table{
  margin-top: 30px !important;
}
.articles-table{
  margin-top: 16px;
  margin-bottom: 16px;
}
.total-view{
  text-align: end;
}
.display-total-items{
  div{
    display: inline-flex;
  }
  
}
.quantity-total-view{
  padding: 8px 0px 4px 8px;
  width: 14%;
  text-align: end;
}
.articles-preview-img{
  margin: auto;
  text-align: center;
}
.paper-article{
  margin-bottom: 26px;
}
.details-view-header{
  margin-top: 26px !important;
  margin-bottom: 28px !important;
}
.article-expandable-view{
  margin-bottom: 26px !important;
}
.search-button{
  text-align: center;
}
.model-null{
  text-align: center;
}
.modal-articles{
  width: 100% !important;
  //height: 60% !important;
}
.table-articles{
  width: 100% !important;
  text-align: -webkit-center;
}
.table-adjustment{
  width: 100% !important;
  text-align: -webkit-center;
}
.align-center{
  text-align: center !important;
  text-align: -webkit-center !important;
}
.edit-button{
  width: 33% !important;
  margin: 8px !important;
}
.edit-button-ver{
  width: 15% !important;
  margin: 8px !important;
}
.edit-button-center{
  text-align: end !important;
}
.details-paper{
  padding: 16px;
}
.edit-buton-hover:hover{
  cursor: pointer !important;
}
.align-end{
  text-align: end !important;
}
.align-start{
  text-align: start !important;
}
.paper-margin{
  margin: 16px 0px 0px 0px;
}
.padding-8{
  padding: 8px !important;
}
.presentation-select{
  overflow: auto;
  max-height: 600px;
  width: auto;
}
.card-header{
  padding: 0px !important;
}
.CardHeader{
  padding: 0px !important;
}
.disable-outline{
  &:hover{
    outline: none !important;
  }
  &:focus{
    outline: none !important;
  }
}
.aquiModal{
  max-width: 70% !important;
  width: 90% !important;
}
.select-warehouse{
  margin-top: 16px;
}
.disable-link {
  pointer-events: none;
}
.edit-button{
  text-align: end;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  margin-right: 10px !important;
  :hover{
   cursor: pointer;
  }  

}

.add-button{
  text-align: -webkit-right;
  margin: 16px;
  width: 25% !important;
}

.details-view{
  margin-bottom: 50px !important;
}
.comment{
  width: 100% !important;
}

.close-modal{
  margin-top: 16px !important;
  margin-left: -10px !important;
}

.fullwith{
  width: 100% !important;
}
.select-margin{
  margin-bottom: 16px !important;
}
.margin-scrap-table{
  margin-top: 16px !important;
}
.success{
  color: green;
  font-size: 18px;
}
.error{
  color: red;
  font-size: 18px;
}
.delta-center{
  text-align: center;
}
.table-row{
  td{
    padding: 8px 10px 8px 16px;
    border-bottom: none !important;
  }
}
.chip-with{
  width: 100px;
}

.phone{
  margin-left: 10px !important;
}

.space-input{
  height: 19px;
}

/* ----------------------------------------------------------------- */
.caja-apps {
  display: flex;
  padding: 15px;
  flex-wrap: wrap;
  //gap: 64px;
  /* justify-content: center; */
  
  /* border: 10px solid yellow; */
}

.contenedor-apps{
    width: 80%;
    margin: auto; 
}
.apps{
  border-radius: "32px";
  /* transition: all 4s; */
}
/* .apps:hover{
  transform: scale(1.1);
 
} */

@media only screen and (max-width: 750px ) {
  .contenedor-apps{
    width: 90%;
    margin: auto;
}


}

@media only screen and (max-width: 600px ) {
  .contenedor-apps{
    
    margin: 0px 16px;
}
.caja-apps {
  justify-content: center;

}


}
@keyframes shake {
  0%{
    transform: translate(0);
  }
  20%,
  40%,
  60%,
  80%{
    transform: translate(.3em);
  
  }
  10%,
  30%,
  50%{
    transform: translate(-.3em);
  }
  70%,
  100%{
    transform: translate(0);
  }
  }

.to-buy{

  display: none;
  filter: grayscale(100%) ;
}
.to-buy:hover{
animation: shake .8s ease-in;


}
 .need-access{

  filter: grayscale(100%);


 /* background: rgb(131, 131, 131); */
}
 .need-access:hover{
animation: shake .8s;



}

.scale-in-center {
	 -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) ; 
	        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) ;
          
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

//POS CSS

.login-back{
  background-color: black;
  background-size: cover;
  background-position: center center;
}
.add-product:hover{
  opacity: 0.3;
}

.border{
  border-color: black;
  border-style: solid;
}

.border-left{
  border-left: 1px solid grey;
}

.border-rigth{
  border-right: 1px solid grey;
}
.border-bottom{
  border-bottom: 1px solid grey;
}
.totalPay{
  font-size: 60px;
  //color: green
}

.paymant-chip{
  border-radius: 4px !important;
  .css-6od3lo-MuiChip-label{
width: 90%;
}
}

.previewPay{
  font-size: 30px;
  //color: green;
}

.previewPaySubtotal{
  font-size: 20px;
  //color: green;
}

.list-sale{
  height: 93%;
  width: 22%;
  position: fixed;
  .shopping-list{
    
    height: 60%;
  }
  .empty-list{
    //width: 18vw;
  }
  .total{
    height: 147px;
    width: 100%;
   
  }
}

.sales-list{
  background-color: whitesmoke;
  min-width: 350px !important;
  border-color: gray;
}

.search-bar{
  margin-left: 0px !important;
}

.pay-button{
  width: 100%;
  margin: auto;
}

.list-products{
  overflow:visible;
  width: auto;
  //margin-right: 19%;
}

.bold-text{
  font-weight: 700;
}

.card-shopping-list{
  width: 100% !important;
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}


.align-text{
  text-align: center;
}
.cash-register-button{
  width: 32%;
  height: 50px;
}
.full-height{
  height: 100%;
}
.full-width{
  width: 100%;
}
.register-background{
  background-color: #1f96f2;
}
.presentation{
  margin-left: 9px;
  //text-align: center;
  max-width: 150px;
}

.card-item{
  //max-width: 83%;
  display: inline-flex;
  overflow-x: scroll;
  margin: auto;
}


.header-product-list{
  margin-bottom: 16px !important;
}
.keypad{
  margin: 8px;
}

