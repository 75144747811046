.box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(97.8vh - 32px);
  background: linear-gradient(0deg, #B1E8F0, #00aec7 50%, #B1E8F0 100%) !important;
  /* background-image: url(../api/data/images/light.png); */
  overflow: hidden;
}

.img-light {
  background-image: url(../api/data/images/joeplanes_Mesa_de_trabajo_1.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.img-dark {
  background-image: url(../api/data/images/joeplanes_Mesa_de_trabajo_1.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.paperForm {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px;

}

.formSizeLogin {
  height: 350px;
}

.formSizeRecoverP {
  height: 250px;
}

.grid-container {
  width: 360px;
}

.formSizeResetP {
  height: 300px;
}