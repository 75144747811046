

.grey-box{
    background-color: rgb(151, 151, 151)
}
.margin-t{
    margin-top: 5px;
    margin-bottom: 16px;
    
}




-----
.margin-tb{
    margin-top: 8px;
    margin-bottom: 8px;

}

.margin-b{
    margin-bottom: 12px;
}

.list{
    max-height: 380px;
    overflow: auto;
}
.list::-webkit-scrollbar {
    width: 5px;
}
.list::-webkit-scrollbar-thumb {
    background: #1f96f2;
    border-radius: 5px;

}


.slider-container{
    width: '50vw';
    
}





/* .container-img{
    display: flex;
    flex-wrap: no-wrap;
    overflow-x: auto;
    margin-bottom: 7px;
    /* border: 1px dashed  gray; 


} */
.container-img{
    margin-bottom: 0px;
}


.container-img::-webkit-scrollbar {
    height: 7px;

}
.container-img::-webkit-scrollbar-thumb {
    background:  #797979;
    border-radius: 10px;

}
.container-img .car-img{
    width: 53%;
    max-width: 230px;
    min-width: 150px;
    flex: 0 0 auto;
    /* border: 2px solid blue ; */
    margin: 10px;
}

.car-img .img-size{
    z-index: 5;
    margin-top: -30px;

    width: 100%;
    height: auto;
}

 .car-img .close-button{
   position: relative;
   left: calc(100% - 30px) ;
  
    width: 30px;
    height: 30px;
    background: rgb(255, 0, 0);
    border-radius: 5px;
    border: none;
    cursor: pointer;


} 


.App .wrap {
  
    color: #fff;
    text-align: center;
    width: 30%;
    height: 30%;
    
  
    box-shadow: 0 0  25px #222;
    backdrop-filter: blur(2px);
    border-radius: 16px;
  }
  
  
// .box {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
  
//     position: absolute;

  
//     width: auto;
//     box-shadow: 0 0  25px #222;
//     padding: 6px 16px 16px 16px;
//     border-radius: 10px;
//   } 


  /* .easy-cropper{
    display: flex;
    max-height: 90vh;
    overflow: auto;
    padding: 16px;
    flex-wrap: wrap;
    width:  100vw;
    overflow: hidden;
    justify-content: center;
  }
  .crop-container {
    position: relative;
    margin: 20px 10px;
    width: 40vw;
  
    height: 40vh;
  
  }
  .crop-result{
    display: inline-block;
  
    height: 40vh;
    margin: 20px 10px;
    display: flex;
    width: 40vw;
    justify-content: center;
  }
  
  .title-editor{
    width: 40vw;
    margin: 5px 10px;
  }
  
  
  .center-div {
    display: flex;
    height: calc(90% - 50px);
    justify-content: center;
    align-items: center;
  }
  
  .controlers{
    margin-top: 32px;
    width: 90%;
    display: flex;
  }
  
  .button-container{
  
    width: 90vw;
    display: flex;
    justify-content: end;
    
  
  }
  .button {
    margin: 0 16px;
  }


  

  .output {
    position: relative;
    width: 300px;
    height: 300px;
    overflow: hidden;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.3);
  }
  
  
  .output img {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left; 
  }
  
 */