.modalBottom .box{
   border-radius: 0px;
   padding-top: 0;
   padding-left: 0px;
 }

 .modalBottom{
   position: fixed;
   bottom: 0;
   width:100%;

 }
