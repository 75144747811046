


/* ------------------------------- */

.paper{
    padding: 16px;
    margin-bottom: 16px;
}
.paper .button-enable{
    color: #fff;
}
.paper .button-disable{
    color: #fff;
    background-color: rgb(59, 59, 59);
}

.paper .button{
    color: #fff;
}
.paper .avatar{
    margin: auto;
    background-color: lightblue;
    width: 116px;
    height: 116px;
    margin-bottom: 30px;
}

.grid-divider{
    display: inline-block;
    
}
.link{
    display: inline-block;
    float: right;
    font-size: 21px;
}

.title-card{
    display: inline-block;
}

.switch{
    margin-bottom: 15px;
}

.left-text{
    display: inline-block;
    float: right;
}